import ReactJson from '@microlink/react-json-view';
import CircularProgress from '@mui/material/CircularProgress';
import { PrimaryButton } from 'components/shared/Button/ActionButtons';
import Modal from 'components/shared/Modal/Modal';
import { useGlobalStore } from 'global-state/useStore';
import { ChangeEvent, useEffect, useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import StationService from 'services/StationService/StationService';
import { fetchCustomConfigFromUrl, handleFileReadAsJSON } from 'utils/functions';

const EditCustomConfigModal = () => {
	const { t } = useTranslation();
	const editCustomConfig = useGlobalStore((state) => state.editCustomConfig);
	const toggleEditCustomConfig = useGlobalStore((state) => state.toggleEditCustomConfig);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const fileInputRef = useRef<HTMLInputElement>(null);

	const [jsonData, setJsonData] = useState({});
	const [loadingJson, setLoadingJson] = useState(false);
	const [loadingCustomConfigList, setLoadingCustomConfigList] = useState(true);

	const handleClose = () => {
		toggleEditCustomConfig(false, null);
	};

	const handleUploadClick = () => {
		if (!fileInputRef.current) return;
		fileInputRef.current.click();
	};

	const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
		handleFileReadAsJSON(event, setJsonData);
	};
	useEffect(() => {
		StationService.getCustomConfig(
			Number(selectedSiteId),
			Number(editCustomConfig.station_id)
		).then((res) => {
			setLoadingCustomConfigList(false);

			if (res.location) {
				setLoadingJson(true);
				fetchCustomConfigFromUrl(res.location)
					.then((result) => {
						setJsonData(result);
						setLoadingJson(false);
					})
					.catch(() => {
						toast.error(t('SOMETHING_WENT_WRONG'));
						setLoadingJson(false);
					});
			}
		});
	}, []);

	return (
		<Modal
			open={editCustomConfig.open}
			closeModal={handleClose}
			modalTitle={t('CUSTOM_CONFIGURATION')}>
			<div className={'flex w-full flex-col items-center gap-md p-4'}>
				<input type="file" ref={fileInputRef} className="hidden" onChange={handleFileChange} />
				<PrimaryButton onClick={handleUploadClick} className={'w-max'}>
					{t('SELECT_FILE')}
				</PrimaryButton>
				{loadingJson || loadingCustomConfigList ? (
					<CircularProgress />
				) : (
					<div className={'my-10 w-full'}>
						<ReactJson
							src={jsonData}
							onEdit={(edit) => {
								setJsonData(edit.updated_src);
							}}
							onAdd={(add) => {
								setJsonData(add.updated_src);
							}}
							onDelete={(del) => {
								setJsonData(del.updated_src);
							}}
						/>
					</div>
				)}
				<PrimaryButton
					disabled={Object.keys(jsonData).length === 0}
					onClick={() =>
						StationService.uploadCustomConfig(
							Number(selectedSiteId),
							Number(editCustomConfig.station_id),
							jsonData
						)
							.then(() => {
								toast.success(t('CONFIG_UPDATED'));
								toggleEditCustomConfig(false, null);
							})
							.catch((err) => {
								toast.error(err.details);
							})
					}>
					{t('UPDATE')}
				</PrimaryButton>
			</div>
		</Modal>
	);
};
export default EditCustomConfigModal;
