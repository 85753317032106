import { Upload, Warning } from '@mui/icons-material';
import { TextField } from '@mui/material';
import { PrimaryButton } from 'components/shared/Button/ActionButtons';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import { useEffect, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { createGCPFormatArray } from 'utils/functions';
import useStationDataFromStore from '../../hooks/useStationDataFromStore';

import ActionIconButton from 'components/shared/Button/ActionIconButton';
import { useCoordinatesValidation } from 'hooks/useCoordinatesValidation';
import { scientificNotationNumberPattern } from 'utils/regex';
import { useFileUpload } from '../../hooks/useFileUpload';
import useStationInfo from '../../hooks/useStationDetailsHook';

const GCPCoordinates = () => {
	const { t } = useTranslation();
	const [fileContent, setFileContent] = useState<string | null>(null);

	const { stationDetails } = useGlobalStore((state) => state.editGCPModal);
	const toggleGCPModal = useGlobalStore((state) => state.toggleEditGCPModal);
	const { stationData, updateStationData } = useStationDataFromStore(stationDetails ?? undefined);
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const { GCPPointsDefined } = useStationInfo(currentOrganization, stationData);

	const formik = useFormik({
		initialValues: {
			markers_coordinates: stationData?.markers_coordinates?.join(',') ?? ''
		},
		onSubmit: (values) => {
			const gcpArray = createGCPFormatArray(values.markers_coordinates);

			const station = {
				...stationData,
				markers_coordinates: gcpArray
			};
			updateStationData(station as any);
			if (Math.floor(gcpArray.length / 3) === 4) {
				formik.resetForm({
					values: {
						markers_coordinates: values.markers_coordinates
					}
				});
			} else toggleGCPModal({ open: false, station_id: null, stationDetails: null });

			toast.success(t('CHANGES_APPLIED'));
			/*
						stationData?.station_id &&
							queryClient.invalidateQueries([
								queryKeys.station_details,
								`site-id-${selectedSiteId}`,
								`station-id-${stationData.station_id}`
							]);*/
			/*				updateStation(
								{
									siteId: Number(selectedSiteId),
									stationId: stationData?.station_id,
									data: station
								},
								{
									onSuccess: () => {
										toast.success(t('STATION_UPDATED'));
										queryClient.invalidateQueries([
											queryKeys.station_details,
											`site-id-${selectedSiteId}`,
											`station-id-${stationData.station_id}`
										]);
									}
								}
							);*/
		}
	});

	const coordinatesValidationError = useCoordinatesValidation(formik.values.markers_coordinates);

	const changeCoordsOnUpload = (coords: string) => {
		const coordinatesParsed = coords.match(scientificNotationNumberPattern) as string[];
		coordinatesParsed && setFileContent(coords);
	};
	const { uploadInputRef, handleUploadClick, handleFileChange } =
		useFileUpload(changeCoordsOnUpload);

	useEffect(() => {
		const coordinatesParsed = fileContent?.match(scientificNotationNumberPattern) as string[];
		coordinatesParsed && formik.setFieldValue('markers_coordinates', fileContent);
	}, [fileContent]);

	return (
		<form onSubmit={formik.handleSubmit}>
			<div className={'flex flex-col gap-md'}>
				<div className={'flex w-full flex-row items-center  gap-xl align-middle'}>
					<div className={'flex w-full flex-col gap-sm'}>
						<div>{t('MARKER_COORDINATES')}</div>
						<TextField
							multiline={true}
							rows={5}
							variant={'standard'}
							className={'w-full'}
							name="markers_coordinates"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.markers_coordinates}
						/>

						<div className={'h-max pb-2 text-red-500'}>
							{coordinatesValidationError.errorMessage}
						</div>
					</div>
					<input
						type="file"
						className={'hidden'}
						ref={uploadInputRef}
						onChange={handleFileChange}
						accept="*"
					/>
					<ActionIconButton
						icon={<Upload onClick={handleUploadClick} />}
						title={t('UPLOAD_FILE')}
					/>
				</div>
				<div className={'flex flex-col gap-sm text-sm'}>
					<div className={'flex items-center gap-sm align-middle text-base'}>
						<Warning color={'error'} /> <span className={'font-bold'}>{t('IMPORTANT')}</span>
					</div>
					<div>{t('COORDINATES_WARNING')}</div>
					<div>{t('COORDINATES_INPUT_WARNING')}</div>
				</div>
				<div className={'flex flex-row justify-center pb-4'}>
					<PrimaryButton
						type={'submit'}
						disabled={!coordinatesValidationError.isValid || !formik.dirty}>
						{t('UPDATE')}
					</PrimaryButton>
				</div>
			</div>
		</form>
	);
};
export default GCPCoordinates;
