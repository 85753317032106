import { useState, useEffect } from 'react';
import { prepareData } from 'utils/freeProfileParamsHelper';
import { validateMarkerCoordinates } from 'utils/functions';

export const useCoordinatesValidation = (coordinates: string) => {
	const [error, setError] = useState({
		errorMessage: '',
		isValid: false
	});

	useEffect(() => {
		setError(validateMarkerCoordinates(prepareData(coordinates ?? '')));
	}, [coordinates]);

	return error;
};
