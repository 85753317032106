import Button from '@mui/material/Button';
import React from 'react';
import { IButtonProps } from './types';

const BaseButton: React.FC<IButtonProps> = ({
	text,
	onClick,
	disabled,
	className,
	type = 'button',
	name,
	children,
	variant = 'contained',
	color = 'primary',
	size = 'medium',
	startIcon,
	endIcon,
	fullWidth
}) => {
	return (
		<Button
			className={`${className ?? ''}`}
			variant={variant}
			type={type}
			name={name}
			color={color}
			size={size}
			disabled={disabled}
			onClick={onClick}
			startIcon={startIcon}
			endIcon={endIcon}
			fullWidth={fullWidth}>
			{text}
			{children}
		</Button>
	);
};

export default BaseButton;
