import { Delete } from '@mui/icons-material';
import CircularProgress from '@mui/material/CircularProgress';
import { useGlobalStore } from 'global-state/useStore';
import { DischargeStationDetails } from 'interfaces/models/DischargeStationDetails';
import DischargeStationForm from 'pages/SiteDetails/components/StationTabs/components/DischargeStationForm';
import { IStationTabProps } from 'pages/SiteDetails/components/types';
import ViewMeasurementsButton from 'pages/SiteDetails/components/ViewMeasurementsButton';
import { FunctionComponent, useEffect } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { queryKeys } from 'rq/constants';
import { useDeleteStation, useStationDetails } from 'rq/hooks/stationHook';
import { queryClient } from 'rq/queryClient';
import {
	handleViewAlarmsClick,
	handleViewMeasurementsClick
} from '../../../../helper/helperFunctions';
import ViewAlarmsButton from '../ViewAlarmsButton';

import ActionIconButton from 'components/shared/Button/ActionIconButton';
const DischargeStationTab: FunctionComponent<IStationTabProps> = ({ station_id }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const { siteId } = useParams();
	const selectedSiteId = useGlobalStore((state) => state.selectedSiteId);
	const setDischargeStationData = useGlobalStore((state) => state.setDischargeStationData);
	const toggleConfirmationDialog = useGlobalStore((state) => state.toggleConfirmationDialog);
	const currentOrganization = useGlobalStore((state) => state.currentOrganization);
	const setStationTabValue = useGlobalStore((state) => state.setStationTabValue);
	const stationTabValue = useGlobalStore((state) => state.stationTabValue);
	const { mutate: deleteStation } = useDeleteStation();
	const { isLoading: stationLoading, data: stationDetails } =
		useStationDetails<DischargeStationDetails>(Number(siteId), station_id, {
			enabled: !!station_id
		});

	useEffect(() => {
		stationDetails && setDischargeStationData(stationDetails);
	}, [stationDetails]);

	if (stationLoading)
		return (
			<div
				className={'flex h-full min-h-[20rem] flex-col items-center justify-center text-secondary'}>
				<CircularProgress size={50} thickness={4} />
			</div>
		);

	const handleStationDelete = () => {
		deleteStation(
			{ site_id: Number(selectedSiteId), station_id: Number(station_id) },
			{
				onSuccess: () => {
					toast.success(t('STATION_DELETED'));
				},
				onSettled: () => {
					queryClient.invalidateQueries([queryKeys.stations, `site-${selectedSiteId}`]);
					setStationTabValue(stationTabValue - 1);
				}
			}
		);
	};

	return (
		<div>
			<div className={'flex w-full flex-row flex-wrap justify-between gap-sm'}>
				<div className={' text-sm italic'}>{t(`DISCHARGE_STATION_DESCRIPTION`)}</div>
				{station_id && (
					<div className={'flex items-center gap-xs align-middle'}>
						<div className={'flex w-full justify-end text-xs'}>
							{t('ID')}: {station_id}
						</div>
						<ActionIconButton
							title={`${t('DELETE')} ${t('STATION').toLowerCase()}`}
							icon={<Delete className={'text-danger'} />}
							size="medium"
							onClick={() => {
								toggleConfirmationDialog({
									confirmAction: handleStationDelete,
									dialogOpen: true,
									type: 'confirmation',
									message: t('DELETE_PROMPT') + '?'
								});
							}}
						/>
					</div>
				)}
			</div>
			<div className={'flex flex-wrap gap-sm'}>
				{' '}
				{station_id && (
					<ViewMeasurementsButton
						handleClick={() =>
							handleViewMeasurementsClick(
								navigate,
								currentOrganization.id,
								Number(selectedSiteId),
								station_id
							)
						}
					/>
				)}
				{station_id && (
					<ViewAlarmsButton
						handleClick={() =>
							handleViewAlarmsClick(
								navigate,
								currentOrganization.id,
								Number(selectedSiteId),
								station_id
							)
						}
					/>
				)}
			</div>
			<DischargeStationForm station_id={station_id} CPFastFlowForm={false} />
		</div>
	);
};
export default DischargeStationTab;
