import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import BlockIcon from '@mui/icons-material/Block';
import HomeIcon from '@mui/icons-material/Home';
import UndoIcon from '@mui/icons-material/Undo';
import { CancelButton, PrimaryButton } from 'components/shared/Button/ActionButtons';

const AccessDenied: FunctionComponent = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	return (
		<div className={'flex flex-col items-center justify-evenly'}>
			<BlockIcon className={'h-2/3 w-2/3 text-red-600 md:h-4/6 md:w-4/6 lg:h-1/3 lg:w-1/3 '} />
			<h3 className={'mb-4 ml-4 ml-auto justify-self-center  md:mx-auto md:mb-2'}>
				{t('NOT_AUTHORIZED')}
			</h3>
			<div className={'flex w-2/3 flex-row flex-wrap items-center justify-evenly gap-md lg:w-1/3'}>
				<CancelButton onClick={() => navigate(-1)}>
					<UndoIcon /> {t('GO_BACK')}
				</CancelButton>
				<PrimaryButton onClick={() => navigate('/')}>
					<HomeIcon /> {t('GO_TO_HOMEPAGE')}
				</PrimaryButton>
			</div>
		</div>
	);
};
export default AccessDenied;
