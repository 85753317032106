import { Download } from '@mui/icons-material';
import axios from 'axios';
import { PrimaryButton } from 'components/shared/Button/ActionButtons';
import Modal from 'components/shared/Modal/Modal';
import { useGlobalStore } from 'global-state/useStore';
import { useRef, useState } from 'react';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import { handleCanvasDrawing } from 'utils/functions';

const MeasurementImageAndVideoModal = () => {
	const { t } = useTranslation();

	const imgRef = useRef<HTMLImageElement>(null);
	const canvasRef = useRef<HTMLCanvasElement>(null);

	const measurementImageVideoModal = useGlobalStore((state) => state.measurementImageVideoModal);
	const toggleMeasurementImageVideoModal = useGlobalStore(
		(state) => state.toggleMeasurementImageVideoModal
	);
	const { measurement } = useGlobalStore((state) => state.measurementImageVideoModal);

	const [containerWidthClass, setContainerWidthClass] = useState('w-[25rem]');

	const onImageLoad = () => {
		if (measurement && imgRef.current && canvasRef.current) {
			handleCanvasDrawing(canvasRef, imgRef, measurement, measurement.image);

			if (imgRef.current.naturalWidth > imgRef.current.naturalHeight) {
				setContainerWidthClass('w-full max-w-full xl:w-[65rem]');
			} else {
				setContainerWidthClass('w-full max-w-full xs:w-[30rem] md:w-[25rem]');
			}
		}
	};

	const downloadImage = () => {
		axios
			.get(measurementImageVideoModal.imageSrc, {
				headers: {
					'Cache-Control': 'no-cache, no-store, must-revalidate'
				},
				responseType: 'blob'
			})
			.then((response) => {
				const url = window.URL.createObjectURL(new Blob([response.data]));
				const link = document.createElement('a');
				link.href = url;
				link.setAttribute(
					'download',
					`${t('MEASUREMENT').toLowerCase()}-${
						measurementImageVideoModal.measurement?.measurement_id
					}.jpeg`
				);
				document.body.appendChild(link);
				link.click();
				document.body.removeChild(link);
			})
			.catch(() => {
				toast.error(t('SOMETHING_WENT_WRONG'));
			});
	};

	return (
		<Modal
			open={measurementImageVideoModal.open}
			closeModal={() =>
				toggleMeasurementImageVideoModal({ modalType: '', imageSrc: '', videoSrc: '' })
			}
			modalTitle={
				measurementImageVideoModal.modalType === 'image'
					? t('MEASUREMENT_IMAGE')
					: t('MEASUREMENT_VIDEO')
			}>
			<div className={'flex'}>
				<div
					className={
						'flex max-h-full w-max max-w-full flex-1 flex-col items-center justify-center gap-md self-center '
					}>
					{measurementImageVideoModal.modalType === 'image' && (
						<div className={'flex w-full flex-col items-center justify-center gap-md'}>
							<div
								className={
									'mt-4 flex h-4 w-max cursor-pointer items-center justify-center self-center  text-center text-secondary'
								}>
								<PrimaryButton onClick={downloadImage}>
									<Download />
								</PrimaryButton>
							</div>
							<div className="relative">
								<img
									id={'measImg'}
									ref={imgRef}
									src={measurementImageVideoModal.imageSrc}
									className={`${containerWidthClass} object-contain`}
									onLoad={onImageLoad}
									alt={t('MEASUREMENT_IMAGE')}
								/>
								<canvas
									className={`absolute left-0 top-0 h-full ${containerWidthClass}`}
									ref={canvasRef}
								/>
							</div>
						</div>
					)}
					{measurementImageVideoModal.modalType === 'video' && (
						<video controls className={'max-h-[46rem] object-contain'}>
							<source src={measurementImageVideoModal.videoSrc} type="video/mp4" />
						</video>
					)}
				</div>
			</div>
		</Modal>
	);
};

export default MeasurementImageAndVideoModal;
