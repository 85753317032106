import { MenuItem, Select } from '@mui/material';
import ListItemText from '@mui/material/ListItemText';
import Tooltip from '@mui/material/Tooltip';
import { ICreateStationModalProps } from 'components/Modal/Station/types';
import Modal from 'components/shared/Modal/Modal';
import { StationTypes } from 'interfaces/models/StationTypes';
import DischargeKeeperStationTab from 'pages/SiteDetails/components/StationTabs/DischargeKeeperStationTab';
import DischargeStationTab from 'pages/SiteDetails/components/StationTabs/DischargeStationTab';
import StationTab from 'pages/SiteDetails/components/StationTabs/StationTab';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { stationTypes } from 'utils/helperData';

const CreateStationModal: FunctionComponent<ICreateStationModalProps> = ({ open, handleClose }) => {
	const { t } = useTranslation();
	const [stationType, setStationType] = useState<StationTypes>('DISCHARGE');

	return (
		<>
			<Modal closeModal={handleClose} open={open} modalTitle={t('ADD_STATION')}>
				<div className={'min-w-2/3 m-4 xl:min-w-[60rem]'}>
					<div className={'flex w-full flex-col gap-sm  pb-12 lg:flex-row'}>
						<div
							className={
								'flex w-full items-center justify-center gap-md normal-case max-md:flex-col'
							}>
							{t('STATION_TYPE')}
							<Select
								className={'mr-2 h-12 w-full max-w-[15rem]'}
								name={'station_type'}
								value={stationType}
								onChange={(e) => setStationType(e.target.value as StationTypes)}>
								{stationTypes.map((stationType) => {
									return (
										<MenuItem value={stationType} key={`station-type-${stationType}`}>
											<Tooltip
												key={`tooltip-${stationType}`}
												title={t(`${stationType}_STATION_DESCRIPTION`)}
												placement="right">
												<ListItemText
													primary={
														stationType === 'DISCHARGE'
															? 'DISCHARGE APP'
															: stationType.replace('_', ' ')
													}
												/>
											</Tooltip>
										</MenuItem>
									);
								})}
							</Select>
						</div>
					</div>
					<div className={'h-screen md:max-h-[30rem]'}>
						{stationType === 'DISCHARGE' && <DischargeStationTab></DischargeStationTab>}
						{stationType === 'DISCHARGE_KEEPER' && (
							<DischargeKeeperStationTab></DischargeKeeperStationTab>
						)}
						{stationType === 'CUSTOM_STATION' && (
							<StationTab stationType={'CUSTOM_STATION'}></StationTab>
						)}
						{stationType === 'WEATHER_STATION' && (
							<StationTab stationType={'WEATHER_STATION'}></StationTab>
						)}
						{stationType === 'POLLUTION_KEEPER' && (
							<StationTab stationType={'POLLUTION_KEEPER'}></StationTab>
						)}
					</div>
				</div>
			</Modal>
		</>
	);
};
export default CreateStationModal;
