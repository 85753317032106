import { Close } from '@mui/icons-material';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import { default as MUIModal } from '@mui/material/Modal';
import { IModalProps } from 'components/shared/Modal/types';
import { FunctionComponent, PropsWithChildren } from 'react';

const Modal: FunctionComponent<PropsWithChildren<IModalProps>> = ({
	children,
	open,
	closeModal,
	modalTitle
}) => {
	return (
		<>
			<MUIModal
				aria-labelledby="transition-modal-title"
				aria-describedby="transition-modal-description"
				open={open}
				onClose={closeModal}
				closeAfterTransition
				className="shadow-xl">
				<Fade in={open}>
					<Box className="absolute left-1/2 top-1/2  -translate-x-1/2 -translate-y-1/2 transform bg-primary shadow-xl shadow-gray-900 outline-none max-md:h-full max-md:w-full md:min-w-[40rem] md:max-w-[90%] ">
						{modalTitle !== '' && (
							<div className="flex w-full justify-between bg-secondary px-2 py-4 text-xl font-medium text-white">
								<span>{modalTitle}</span>
								<div className="cursor-pointer" onClick={closeModal}>
									<Close />
								</div>
							</div>
						)}
						<div className="h-full max-h-screen overflow-y-auto md:max-h-[90vh]">{children}</div>
					</Box>
				</Fade>
			</MUIModal>
		</>
	);
};
export default Modal;
