import { TextField } from '@mui/material';
import { PrimaryButton } from 'components/shared/Button/ActionButtons';
import { useFormik } from 'formik';
import { useGlobalStore } from 'global-state/useStore';
import toast from 'react-hot-toast';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import useStationDataFromStore from '../../hooks/useStationDataFromStore';

const GCPMeasurements = () => {
	const { t } = useTranslation();
	const { stationDetails } = useGlobalStore((state) => state.editGCPModal);

	const { stationData, updateStationData } = useStationDataFromStore(stationDetails ?? undefined);

	const formik = useFormik({
		initialValues: {
			markers_1_2: stationData?.markers_measurements ? stationData.markers_measurements[0] : '',
			markers_3_4: stationData?.markers_measurements ? stationData.markers_measurements[1] : '',
			markers_1_4: stationData?.markers_measurements ? stationData.markers_measurements[2] : '',
			markers_2_3: stationData?.markers_measurements ? stationData.markers_measurements[3] : '',
			dfs_1_2: stationData?.markers_measurements ? stationData.markers_measurements[4] : '',
			dfs_3_4: stationData?.markers_measurements ? stationData.markers_measurements[5] : '',
			dfs_1_4: stationData?.markers_measurements ? stationData.markers_measurements[6] : '',
			dfs_2_3: stationData?.markers_measurements ? stationData.markers_measurements[7] : ''
		},
		validationSchema: Yup.object({
			markers_1_2: Yup.number().required(),
			markers_3_4: Yup.number().required(),
			markers_1_4: Yup.number().required(),
			markers_2_3: Yup.number().required(),
			dfs_1_2: Yup.number().required(),
			dfs_3_4: Yup.number().required(),
			dfs_1_4: Yup.number().required(),
			dfs_2_3: Yup.number().required()
		}),
		onSubmit: (values) => {
			/*	const station = {
          ...stationDetails,
          markers_measurements: values.markers_measurements.split(',').map(Number)
        };*/
			const markerMeasurements = Object.values(values).concat([0, 0, 0, 0, 0, 0]);
			const station = {
				...stationData,
				markers_measurements: markerMeasurements
			};
			updateStationData(station as any);
			toast.success(t('CHANGES_APPLIED'));
			/*
						stationData?.station_id &&
							updateStation(
								{
									siteId: Number(selectedSiteId),
									stationId: stationData?.station_id,
									data: station
								},
								{
									onSuccess: () => {
										toast.success(t('STATION_UPDATED'));
										queryClient.invalidateQueries([
											queryKeys.station_details,
											`site-id-${selectedSiteId}`,
											`station-id-${stationData.station_id}`
										]);
									}
								}
							);*/
		}
	});

	return (
		<form onSubmit={formik.handleSubmit}>
			<div className={'flex w-full flex-col gap-xl'}>
				<div className={'flex flex-col gap-sm'}>
					<div>{t('DISTANCE_BETWEEN_MARKERS')} (m)</div>
					<div className={'flex flex-wrap  gap-sm'}>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('MARKERS')} 1-2 (m)*`}
							name="markers_1_2"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.markers_1_2 || ''}
							error={formik.touched.markers_1_2 && Boolean(formik.errors.markers_1_2)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('MARKERS')} 3-4 (m)*`}
							name="markers_3_4"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.markers_3_4 || ''}
							error={formik.touched.markers_3_4 && Boolean(formik.errors.markers_3_4)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('MARKERS')} 1-4 (m)*`}
							name="markers_1_4"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.markers_1_4 || ''}
							error={formik.touched.markers_1_4 && Boolean(formik.errors.markers_1_4)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('MARKERS')} 2-3 (m)*`}
							name="markers_2_3"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.markers_2_3 || ''}
							error={formik.touched.markers_2_3 && Boolean(formik.errors.markers_2_3)}
						/>
					</div>
				</div>
				<div className={'flex flex-col gap-sm'}>
					<div>{t('DISTANCE_FROM_SHORE')} (m)</div>
					<div className={'flex flex-wrap  gap-sm'}>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('DFS')} 1-2 (m)*`}
							name="dfs_1_2"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.dfs_1_2 || ''}
							error={formik.touched.dfs_1_2 && Boolean(formik.errors.dfs_1_2)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('DFS')} 3-4 (m)*`}
							name="dfs_3_4"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.dfs_3_4 || ''}
							error={formik.touched.dfs_3_4 && Boolean(formik.errors.dfs_3_4)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('DFS')} 1-4 (m)*`}
							name="dfs_1_4"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.dfs_1_4 || ''}
							error={formik.touched.dfs_1_4 && Boolean(formik.errors.dfs_1_4)}
						/>
						<TextField
							variant={'standard'}
							type={'number'}
							className={'w-32'}
							label={`${t('DFS')} 2-3 (m)*`}
							name="dfs_2_3"
							onChange={formik.handleChange}
							onBlur={formik.handleBlur}
							value={formik.values.dfs_2_3 || ''}
							error={formik.touched.dfs_2_3 && Boolean(formik.errors.dfs_2_3)}
						/>
					</div>
				</div>
				<div className={'flex w-full justify-center'}>
					<div className={'w-full xl:w-3/4'}>
						<img src={'/assets/furrow_geom4markers_new.png'} alt={'4 markers geometry'} />
					</div>
				</div>
				<div className={'flex flex-row justify-center py-4'}>
					<PrimaryButton type={'submit'} disabled={!formik.isValid || !formik.dirty}>
						{t('UPDATE')}
					</PrimaryButton>
				</div>
			</div>
		</form>
	);
};
export default GCPMeasurements;
