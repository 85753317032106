import TravelExploreIcon from '@mui/icons-material/TravelExplore';
import { Divider, List, ListItem, TextField } from '@mui/material';
import CircularProgress from '@mui/material/CircularProgress';
import { CancelButton } from 'components/shared/Button/ActionButtons';
import Modal from 'components/shared/Modal/Modal';
import { IModalProps } from 'components/shared/Modal/types';
import { useGlobalStore } from 'global-state/useStore';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useGlobalSearch } from 'rq/hooks/searchHook';
import { useSitesPaginated } from 'rq/hooks/sitesHook';
import useDebounce from 'rq/hooks/useDebounce';
import { SiteDetailsModel } from '../../../interfaces/models/SiteDetailsModel';

const GlobalSearchModal: FunctionComponent<IModalProps> = ({ closeModal, open }) => {
	const { t } = useTranslation();
	const navigate = useNavigate();

	const globalSearchToggled = useGlobalStore((state) => state.globalSearchToggled);
	const toggleGlobalSearchBar = useGlobalStore((state) => state.toggleGlobalSearchBar);
	const toggleOrganizationSwitchModal = useGlobalStore(
		(state) => state.toggleOrganizationSwitchModal
	);
	const setSelectedSiteId = useGlobalStore((state) => state.setSelectedSiteId);

	const currentOrganization = useGlobalStore((state) => state.currentOrganization);

	const [filter, setFilter] = useState('');

	const debouncedSearchQuery = useDebounce(filter);

	const { data: sites, isFetching: sitesFetching } = useSitesPaginated(
		{
			queryParams: { search: debouncedSearchQuery, page: 1, page_size: 10 }
		},
		{
			keepPreviousData: true,
			enabled: debouncedSearchQuery.length > 2 && globalSearchToggled.searchType !== 'everywhere'
		}
	);
	const { data: globalSites, isFetching: globalSitesFetching } = useGlobalSearch(
		{
			searchTerm: debouncedSearchQuery,
			entity: 'site'
		},
		{ enabled: debouncedSearchQuery.length > 2 && globalSearchToggled.searchType === 'everywhere' }
	);

	const handleSiteFromOtherOrgClick = (option: any) => {
		toggleGlobalSearchBar({ toggled: false, searchType: null });
		if (
			option.organizations.length === 1 &&
			option.organizations[0].id === currentOrganization.id
		) {
			setSelectedSiteId(option.id);
			navigate(`/${currentOrganization.id}/sites/${option.id}`);
			toggleGlobalSearchBar({
				toggled: false,
				searchType: null
			});
		} else
			toggleOrganizationSwitchModal({
				organizations: option.organizations,
				toggled: true,
				siteId: option.id
			});
	};

	const handleSiteClick = (value: SiteDetailsModel) => {
		setFilter(value?.name ?? '');
		if (globalSearchToggled.searchType !== 'everywhere' && !!value) {
			setSelectedSiteId(value.site_id);
			navigate(`/${currentOrganization.id}/sites/${value.site_id}`);
			toggleGlobalSearchBar({
				toggled: false,
				searchType: null
			});
		}
	};

	return (
		<Modal open={open} closeModal={closeModal} modalTitle={''}>
			<div className={'flex flex-col items-center justify-center p-4'}>
				<div className={'flex flex-row items-center justify-between'}>
					<TextField
						placeholder={t('SEARCH_SITES')}
						className={'mr-1'}
						onChange={(e) => setFilter(e.target.value)}></TextField>{' '}
				</div>
				<div className={'my-4 flex w-full flex-col justify-center overflow-scroll md:h-96'}>
					{sitesFetching || globalSitesFetching ? (
						<div className={'flex w-full justify-center'}>
							<CircularProgress />
						</div>
					) : (
						<List>
							{globalSearchToggled.searchType !== 'everywhere' &&
							sites?.results &&
							filter.length > 2 &&
							sites.results.length > 0
								? sites?.results?.map((site) => {
										return (
											<>
												<ListItem
													key={site.site_id}
													className={'w-full cursor-pointer'}
													onClick={() => handleSiteClick(site)}>
													{site.name}
												</ListItem>
												<Divider />
											</>
										);
								  })
								: filter.length < 3
								? t('SEARCH_TERM_MIN_CHARS', { value: 3 })
								: globalSearchToggled.searchType !== 'everywhere' && (
										<div>{`${t('NO_SITES')} ${t('WERE_FOUND')}`}</div>
								  )}
							{globalSearchToggled.searchType === 'everywhere' &&
								globalSites?.site.results?.map((option) => {
									return (
										<>
											<ListItem
												key={option.name}
												className={'cursor-pointer'}
												onClick={() => handleSiteFromOtherOrgClick(option)}>
												<div>
													{option.name}
													{globalSearchToggled.searchType === 'everywhere' && (
														<div className={' flex flex-wrap text-xs font-bold '}>
															<>{t('ORGANIZATIONS')}:</>
															{option.organizations?.map((e, index: number) => {
																return (
																	<span key={e.name} className={'ml-1 font-normal '}>
																		{e.name}
																		{index !== option.organizations.length - 1 && ','}
																	</span>
																);
															})}
														</div>
													)}
												</div>
											</ListItem>
											<Divider />
										</>
									);
								})}
							{filter.length > 2 && (
								<ListItem
									key={'search_everywhere_toggle'}
									className={'mt-4 flex w-full items-center justify-center font-bold text-black'}
									onClick={() => {
										toggleGlobalSearchBar({
											toggled: true,
											searchType: 'everywhere'
										});
									}}>
									<TravelExploreIcon className={'mr-2 text-accent'} />
									<>{t('SEARCH_EVERYWHERE')}</>
								</ListItem>
							)}
						</List>
					)}
				</div>
				<CancelButton onClick={closeModal} text={t('CANCEL')} />
			</div>
		</Modal>
	);
};
export default GlobalSearchModal;
