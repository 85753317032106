import { useRef } from 'react';
import { handleFileReadAsString } from 'utils/functions';

export const useFileUpload = (onUpload: (content: string) => void) => {
	const uploadInputRef = useRef<HTMLInputElement | null>(null);

	const handleUploadClick = () => {
		uploadInputRef.current?.click();
	};

	const handleFileChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		handleFileReadAsString(e, onUpload);
	};

	return { uploadInputRef, handleUploadClick, handleFileChange };
};
