import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import { IFilterComponentProps } from 'components/Measurements/MeasurementsFilter/types';
import { useGlobalStore } from 'global-state/useStore';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { numericFilterOperators } from 'utils/helperData';

const NumericFilterComponent: FunctionComponent<IFilterComponentProps> = ({
	filterFieldNumber,
	selectedFilter
}) => {
	const { t } = useTranslation();
	const selectedMeasurementFilters = useGlobalStore((state) => state.selectedMeasurementFilters);
	const setSelectedMeasurementFilter = useGlobalStore(
		(state) => state.setSelectedMeasurementFilter
	);

	const [selectedFilterState, setSelectedFilterState] = useState(selectedFilter);

	const handleOperatorChange = (operatorName: string) => {
		const filter = { ...selectedFilterState };
		const filters = [...selectedMeasurementFilters];
		filter.operator = numericFilterOperators.find(
			(operator) => operator.label === operatorName
		) ?? {
			operator: '',
			value: '',
			label: ''
		};
		filters[filterFieldNumber] = filter;
		setSelectedFilterState(filter);
		setSelectedMeasurementFilter(filters);
	};

	const handleFilterValueChange = (value: number) => {
		const filter = { ...selectedFilterState };
		const filters = [...selectedMeasurementFilters];
		filter.filterValue = value;

		filters[filterFieldNumber] = filter;
		setSelectedFilterState(filter);
		setSelectedMeasurementFilter(filters);
	};

	return (
		<>
			<div className={'flex flex-wrap gap-md'}>
				<FormControl required size={'small'}>
					<InputLabel id="demo-simple-select-required-label" className={'pb-2'}>
						{t('OPERATOR')}
					</InputLabel>
					<Select
						className={'w-52 lowercase'}
						size={'small'}
						label={t('OPERATOR')}
						name={`numeric-filter-${filterFieldNumber}`}
						required
						value={selectedFilterState.operator.label ?? ''}
						onChange={(e) => handleOperatorChange(e.target.value)}>
						{numericFilterOperators.map((operator) => (
							<MenuItem key={operator.operator} value={operator.label} className={'normal-case'}>
								<div className={'lowercase'}>{t(operator.label)}</div>
							</MenuItem>
						))}
					</Select>
				</FormControl>
				<TextField
					type={'number'}
					className={'w-28 '}
					label={t('VALUE')}
					size={'small'}
					value={selectedFilterState.filterValue}
					required
					onChange={(e) => handleFilterValueChange(Number(e.target.value))}
					InputProps={{
						inputProps: { min: 0 }
					}}
				/>
			</div>
		</>
	);
};
export default NumericFilterComponent;
