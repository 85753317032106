import { Alarm } from '@mui/icons-material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { IButtonLinkProps } from './types';

const ViewAlarmsButton: FunctionComponent<IButtonLinkProps> = ({ handleClick }) => {
	const { t } = useTranslation();

	return (
		<>
			<div className={'flex flex-row gap-sm align-middle'}>
				<Alarm className={'text-gray-500'}></Alarm>
				<a className={' cursor-pointer text-secondary underline'} onClick={handleClick}>
					{t('ALARMS')}
				</a>
			</div>
		</>
	);
};
export default ViewAlarmsButton;
