import { CancelButton, PrimaryButton } from 'components/shared/Button/ActionButtons';
import Modal from 'components/shared/Modal/Modal';
import { useFormik } from 'formik';
import useCountries from 'hooks/useCountries';
import { UserListRetrieve } from 'interfaces/models/UserListRetrieve';
import { useState } from 'react';
import toast from 'react-hot-toast';
import { useAuth } from 'rq/hooks/authHook';
import { OrganizationsService } from 'services/OrganizationsService/OrganizationService';
import * as Yup from 'yup';

import { displayUserFullName } from 'helper/helperFunctions';
import { useTranslation } from 'react-i18next';
import FormStepOne from './FormStepOne';
import FormStepTwo from './FormStepTwo';
import './styles.scss';
import { ICreateOrgModalProps } from './types';

const CreateOrganizationModal = ({
	open,
	closeModal,
	afterCreatingOrganization
}: ICreateOrgModalProps) => {
	const { t } = useTranslation();
	const [formStep, setFormStep] = useState<number>(1);
	const countries = useCountries();

	const { data: user } = useAuth();
	const userFullName = displayUserFullName(user as UserListRetrieve) as string;

	const [usersList, setUsersList] = useState<{ id: number; name: string }[]>([
		{
			id: Number(user?.id),
			name: userFullName
		}
	]);

	const isValidChar = (value: string) => /(\+|[0-9])/.test(value);

	const validationSchema = Yup.object().shape({
		name: Yup.string().required(t('REQUIRED')),
		country: Yup.string().required(t('REQUIRED')),
		email: Yup.string().email(t('INVALID_EMAIL')).nullable(),
		phone: Yup.string()
			.max(20)
			.test('phoneNum', t('INVALID_PHONE_NUMBER'), (value) => {
				return (value === undefined || (value && value.split('').every(isValidChar))) as boolean;
			})
	});

	const formik = useFormik({
		initialValues: {
			name: '',
			address: '',
			email: '',
			phone: '',
			country: ''
		},
		validationSchema: validationSchema,
		onSubmit: async (data, { setErrors }) => {
			toast(t('CREATING_NEW_ORG_LOADER'));
			OrganizationsService.organizationCreate({
				...data
			})
				.then((responseOrg) => {
					const orgId = responseOrg.id as number;
					const promises: Promise<{ affiliation_id: number }>[] = [];
					for (let i = 0; i < usersList.length; i++) {
						if (usersList[i].id !== user?.id) {
							promises.push(
								OrganizationsService.createOrganizationsMembership({
									organizationId: orgId?.toString(),
									data: {
										user: usersList[i].id
									}
								})
							);
						}
					}
					Promise.allSettled(promises).then(() => {
						toast.success(t('ORG_CREATED'));
						closeModal();
						afterCreatingOrganization(responseOrg);
					});
				})
				.catch((error) => {
					toast.error(t('ORG_NOT_CREATED'));
					setErrors(error.body?.form_errors);
					setFormStep(1);
				});
		}
	});

	const incrementFormStep = () => {
		event?.preventDefault();
		setFormStep((prevValue) => prevValue + 1);
	};

	const decrementFormStep = () => {
		setFormStep((prevValue) => prevValue - 1);
	};

	return (
		<Modal open={open} closeModal={closeModal} modalTitle={t('CREATE_ORGANIZATION')}>
			<form
				className="flex w-full flex-1 flex-col overflow-y-hidden px-4 py-4 pt-2 md:px-8 md:py-8"
				onSubmit={formik.handleSubmit}
				autoComplete="off">
				<FormStepOne formStep={formStep} formik={formik} countries={countries} />
				<FormStepTwo usersList={usersList} setUsersList={setUsersList} formStep={formStep} />
				<div className="mb-2 mt-2 flex flex-row justify-center gap-md text-secondary md:mt-5">
					{formStep === 1 ? (
						<CancelButton onClick={closeModal} text={t('CANCEL')} />
					) : (
						<CancelButton onClick={decrementFormStep} text={t('BACK')} />
					)}
					{formStep !== 2 ? (
						<PrimaryButton
							onClick={incrementFormStep}
							disabled={!formik.isValid || !formik.dirty}
							text={t('NEXT')}
						/>
					) : (
						<PrimaryButton
							disabled={!formik.isValid || !formik.dirty}
							type="submit"
							text={t('CREATE_ORGANIZATION')}
						/>
					)}
				</div>
			</form>
		</Modal>
	);
};

export default CreateOrganizationModal;
